<template>
	<div class="User">
		
		<div class="Top">
			<div class="Left">
				<el-button type="primary" @click="$Jump('/do/menu/info/add')">添加导航</el-button>
				
			</div>
			<div class="Right">

				<span style="margin-left: 10px;">
					<label>状态</label>
					<el-select v-model="Search.Status" size="mini">
						<el-option :value="''" label="全部状态"></el-option>
						<el-option :value="50" label="展示中"></el-option>
						<el-option :value="10" label="不展示"></el-option>
					</el-select>
				</span>
				
				<span style="margin-left: 10px;">
					<label>导航编号</label>
					<el-input size="mini" v-model="Search.Id"></el-input>
				</span>
				
				<span style="margin-left: 10px;">
					<label>特征组</label>
					<el-select v-model="Search.SKey" size="mini">
						<el-option :value="''" label="全部"></el-option>
						<el-option :value="'header_menus'" label="商城头部导航"></el-option>
					</el-select>
				</span>
				
				<span style="margin-left: 10px;">
					<label>关键字</label>
					<el-input size="mini" v-model="Search.Name"></el-input>
				</span>
				
				<span style="margin-left: 10px;">
					<label style="color: rgba(0,0,0,0);">搜索</label>
					<el-button size="mini" type="primary" @click="StartSearching()">搜索</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="Title">
				<div class="C1">名称</div>
				<div class="C2">区域</div>
				<div class="C2">状态</div>
				<div class="C3">排序</div>
				<div class="C4">创建时间</div>
				<div class="C5">菜单编号</div>
				<div class="C6">操作</div>
			</div>
			
			<div class="One" v-for="item in NewsList" :key="item.Id">
				<li>
					<div class="C1">{{item.Name}}</div>
					<div class="C2">{{item.SKey == 'header_menus' ? '商城头部导航':'其它区域'}}</div>
					<div class="C2">{{item.StatusName}}</div>
					<div class="C3">{{item.Sort}}</div>
					<div class="C4">{{item.CreatedAt}}</div>
					<div class="C5">{{item.Id}}</div>
					<div class="C6">
						<el-button size="mini" @click="$Jump('/do/menu/info/'+item.Id)">管理</el-button>
					</div>
				</li>
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'MenuList',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				  Id:'',
				  Platform:'',
				  Name:'',
				  SKey:'',
				  Status:'',
			  },
			  NewsList:[],
			  Page:1,
			  PageSize:15,
			  Total:0
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.StartSearching()
	  },
	  methods:{
		  handleCurrentChange(val){
			 this.GetList(val)
		  },
		  StartSearching(){
			  this.GetList(this.Page)
			  this.GetCount()
		  },
		  GetList(_page){
		  		let data = {
		  			Service:'Ad',
		  			Class: 'Menu',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
		  			Id:this.Search.Id,
		  			Name:this.Search.Name,
		  			Status:this.Search.Status,
					SKey:this.Search.SKey
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
					this.NewsList = res.Data.MenuList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
					this.$message('网络请求错误')
		  		})
		  },
		  GetCount(){
		  		let data = {
		  			Service:'Ad',
		  			Class: 'Menu',
		  			Action: 'Count',
		  			Id:this.Search.Id,
		  			Name:this.Search.Name,
		  			Status:this.Search.Status,
		  			SKey:this.Search.SKey
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  					this.Total = res.Data.Count
		  			
		  		})
		  		.catch(function (response) {
		  					this.$message('网络请求错误')
		  		})
		  }
	  }
	}
</script>

<style scoped>
.User{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.User .Top{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-bottom: 20px;
}
.User .Top .Left{
	
}
.User .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	justify-content: flex-end;
}
.User .Top .Right span{
	margin-left: 10px;
}
.User .Top .Right label{
	display: block;
	margin-bottom: 5px;
	font-size: 0.9rem;
	color: rgba(0,0,0,0.4);
}
.User .List{
}
.C1,.C2,.C3,.C4,.C5{
	width: 150px;
}
.C5{
	width: 250px;
	color: rgba(0,0,0,0.4);
}
.C1{
	padding-left: 10px;
}

.C6{
	width: 100px;
	text-align: right;
	padding-right: 10px;
}
.C1{
	flex: 1;
}
.C2{
	
}
.C3{
	width: 80px;
}
.C4{
	width: 100px;
	color: rgba(0,0,0,0.4);
}
.User .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.User .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.User .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.User .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
</style>
